import React from "react";
import FooterImg from "../../assets/img/footer.png";
import { ReactComponent as Logo } from "../../assets/Logo/aardvark-logo-white.svg";

import "./Footer.scss";

const Footer = () => {
  return (
    <div className="footer__container">
      <img src={FooterImg} alt="Footer img" />
      <footer className="footer">
        <div className="footer-row-1">
          <Logo />
          <p>
            This Pet Group LTD
            <br />
            New Kings Beam House, London, SE1 9PD
            <br />
          </p>
          <p>
            For media inquiries, please contact:
            <br />
            Media@thispetgroup.com <br />
            For any customer queries please ring: <br />
            +44 203 318 7673
          </p>
        </div>
        <div className="footer-row-2">
        This Pet Group is the holding company which has ownership of This Dog Is Unique and Aardvark Petcare. This Pet Group is independent and not owned by a parent company. This Pet Group website is informational in nature as to the business of the group. If customers wish to receive information on either This Dog Is Unique or Aardvark, then they should use the help function or email which can be found on the respective websites. The customer helpdesk email for Aardvark is help@aardvark.store and for This Dog Is Unique it is: care@thisdogisunique.com.
        </div>
      </footer>
    </div>
  );
};

export default Footer;
