import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/Logo/Logo.svg";

import "./Investors.scss";

const Investors = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Email", email);
    console.log("Password", password);
    console.log("Remember me", rememberMe);
    setEmail("");
    setPassword("");
    rememberMe(false);
  };

  const navigate = useNavigate("/");

  return (
    <div className="investors">
      <header>
        <Logo onClick={() => navigate("/")} />
      </header>
      <form onSubmit={handleSubmit}>
        <div className="investors__title">Investors Login</div>
        <input
          type="email"
          placeholder="Email"
          value={email}
          className="investors__input"
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          className="investors__input"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className="investors__form-box">
          <label>
            <input
              type="checkbox"
              checked={rememberMe}
              onChange={(e) => setRememberMe(!rememberMe)}
            />
            Remember me
          </label>
          <Link>Forgot Password?</Link>
        </div>
        <button type="submit">Login</button>
      </form>
      <div className="investors__footer">
        This Pet Group is the holding company which has ownership of This Dog Is
        Unique and Aardvark Petcare. This Pet Group is independent and not owned
        by a parent company. This Pet Group website is informational in nature
        as to the business of the group. If customers wish to receive
        information on either This Dog Is Unique or Aardvark, then they should
        use the help function or email which can be found on the respective
        websites. The customer helpdesk email for Aardvark is
        help@aardvark.store and for This Dog Is Unique it is:
        care@thisdogisunique.com.
      </div>
    </div>
  );
};

export default Investors;
