import React from "react";
import { ReactComponent as Logo } from "../../assets/Logo/Logo.svg";
import { ReactComponent as AardvarkLogo } from "../../assets/Logo/aardvark-logo.svg";
import { ReactComponent as TDIULogo } from "../../assets/Logo/tdiu-logo.svg";

import "./Home.scss";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  return (
    <div className="home">
      <div className="home__hero">
        <div className="home__hero-nav">
          <Logo />
          <button onClick={() => navigate("/investors")}>Investor Login</button>
        </div>
        <p>
          This Pet Group is an independent parent company that focuses on using
          technology and science to make pets lives happier and healthier.
        </p>
      </div>

      <div className="home__container">
        <div className="home__container-col">
          <div className="home__container-title">Our Mission</div>
          <p className="home__container-mission">
            Our mission is to create a more sustainable world in which pets are
            both happier and healthier. Our approach is to use advances in
            technology, sustainability, and science to develop products and
            brands which will make a significant difference to pets lives. We
            have particular focus on taking transformative human health advances
            and adapting them to help companion animals.
          </p>
        </div>
        <div className="home__container-col">
          <div className="home__container-title">Our Brands</div>
          <div className="home__container-brand">
            <AardvarkLogo />
            <p>
              Launched in 2021, Aardvark uses insect protein to create
              hypoallergenic food for dogs and cats. We were the first company
              globally to develop insect-based food for cats without the use of
              animal meat. Our food has a significantly reduced environmental
              footprint while being highly beneficial to companion animals with
              intolerances to conventional animal meat.
            </p>
          </div>
          <div className="home__container-brand">
            <TDIULogo />
            <p>
              Launching December 2022, This Dog Is Unique is the worlds first
              fully personalised supplement for companion animals. We can
              formulate more than 28 billion different nutraceutical products to
              cover the full spectrum of dog health needs. Our technology and
              production methods are unique and all formulations are vet
              approved and formulated.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
